import React, {Component} from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Clutch from "../components/clutch"
import Hero__Banner from "../images/hero__banner.svg"
import Mgento_Migration from "../images/m1-to-m2-migration-service.svg"
import M2_Hand from "../images/mg__m2-hand.png"
import Mg_Arrow from "../images/mg__arrow.png"
import Mg_Time from "../images/mg__time-bg.svg"
import {Helmet} from "react-helmet"

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state={
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
        <Layout>
          <Helmet>
            <title>Magento 2 Migration Service: Migrate Magento 1 to Magento 2 | CueForGood</title>
            <meta name="description"
                  content="Upgrade from Magento 1.x to Magento 2.x. Magento 2 promises better security, faster performance & improved checkout. Support ends June 2020."/>
            <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <meta charset="utf-8"/>
            <link rel="canonical" href={this.state.url}/>

            <meta property="og:site_name" content="CueForGood"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content={this.state.url}/>
            <meta property="og:title"
                  content="Magento 2 Migration Service: Migrate Magento 1 to Magento 2 | CueForGood"/>
            <meta property="og:description"
                  content="Upgrade from Magento 1.x to Magento 2.x. Magento 2 promises better security, faster performance & improved checkout. Support ends June 2020."/>
            <meta property="og:image" content="https://www.CueForGood.com/images/cb-m1-m2.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="Upgrade from Magento 1.x to Magento 2.x. Magento 2 promises better security, faster performance & improved checkout. Support ends June 2020."/>
            <meta name="twitter:title"
                  content="Magento 2 Migration Service: Migrate Magento 1 to Magento 2 | CueForGood"/>
            <meta name="twitter:site" content="@CueForGood"/>
            <meta name="twitter:image" content="https://www.CueForGood.com/images/cb-m1-m2.jpg"/>
          </Helmet>
          <section className="  innerpages_space">
            <div className=" m2_migration ">
              <div className="container">
                <div className="mg__hero--cont">
                  <h1 className="migration__title heading_main">
                    <span>Magento 1 to </span>
                    <span>Magento 2 migration</span>
                  </h1>
                  <div className="mg__hero--banner">
                    <img
                        src={Hero__Banner}
                        alt="Magento Migration"
                        className="img-adjust"
                    />
                  </div>
                </div>

                <div className="mg__why--section ">
                  <h2 className="heading_main">
                    Why do you need to migrate from <br/>
                    Magento 1 to Magento&nbsp;2?
                  </h2>
                  <ul>
                    <li>
                      Magento has announced the ‘End of Life’ for its Magento1.x
                      version with effect from June 2020. This means no new features,
                      no official support, and no security updates/patches will be
                      rolled out for Magento 1.x users around the globe.
                    </li>
                    <li>
                      If you are on M1 currently, then it means the countdown has
                      started for your store. You should start the M2 upgrade planning
                      immediately. It is not a luxury, but a necessity for any store
                      on Magento 1.
                    </li>
                  </ul>
                </div>

                <div className="mg__can--section ">
                  <h2 className="heading_main">
                    Can I still be on M1 after June 2020?
                  </h2>
                  <div className="mg__can--bottom ">
                    <p className="mg-full">
                      Well yes you can, but it is not recommended. If are still on M1
                      after June 2020, it is akin to using a car knowing fully well
                      that the engine and various parts which make that car function,
                      will not have spare parts or suitable replacements. You are more
                      susceptible to getting stranded and the car becomes very
                      unreliable and costly to maintain.
                      <br/>
                      <br/>
                      Even the modules and extensions that you are using would become
                      dated as the extension developers will focus more on investing
                      on the M2 compatible versions of their extensions. Everyone will
                      eventually move to M2, so they have no incentive to keep
                      improving/supporting an older version of their extension. <br/>
                      <br/>
                      So continuing to run your online business on a defunct system
                      (after June 2020), is not a good idea.
                      <br/> <br/>
                      We specialise in Magento 2 migration. Drop us a line to get an
                      estimate for upgrading to M2. <br/>
                    </p>
                    <img
                        src={Mgento_Migration}
                        alt="Magento 1 to Magento 2 Migration Service"
                        className="img-adjust"
                    />
                  </div>
                </div>

                <div className="mg__sp--section">
            <span className="mg__hand--icon">
              <img
                  src={M2_Hand}
                  alt="Migrate to Magento 2"
                  className="img-adjust"
              />
            </span>
                  <h2 className="heading_main">
                    <span>We specialize in</span>
                    <span>
                <b>Magento&nbsp;2</b>&nbsp;migration.
              </span>
                  </h2>
                  <p>Drop us a line to get an estimate for upgrading to M2.</p>
                  <div className="mg__contact--button">
                    <Link to="/contact/" id="first-focus-element" className="btn main_cta focus-visible">
                      contact us
                    </Link>
                  </div>
                </div>

                <div className="mg__time--section ">
                  <h2 className="heading_main">
                    How much time does a Magento 1 to Magento&nbsp;2&nbsp;upgrade
                    take?
                  </h2>
                  <div className="mg__time--bottom ">
                    <div className="mg__time--left ">
                      <p>
                        Any Magento 1 to Magento 2 migration/ upgrade can take a
                        minimum of 2 to 3 months, with larger and more complex stores
                        taking as much as 6 to 8 months as well. In your planning for
                        the upgrade, you should factor this time period so you are
                        well in time for this crucial upgrade for your online
                        business.
                        <br/> <br/>
                        You must also involve your SEO team to carefully handle this
                        upgrade, so that there is minimal organic traffic loss.
                        <br/> <br/>
                        <strong>
                    Magento 2 is also more powerful than Magento 1 in many
                    aspects. Here are some of the improvements in M2 which make
                    this upgrade even more useful for your business:
                  </strong>
                      </p>
                      <ul className="mg__time--list">
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            Magento 2 offers some really helpful features like the
                            Enhanced Indexer which will help you increase the query
                            performance speed that will eventually improve your
                            store’s performance.
                          </p>
                        </li>
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            The Progressive Web App (PWA) Studio by Magento 2 offers
                            its users the ability to turn your online website into a
                            fully functional mobile application.
                          </p>
                        </li>
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            Features like reduced load time along with a responsive
                            front end make the search engines fall in love with
                            Magento 2.
                          </p>
                        </li>
                        <li>
                    <span>
                      <img
                          src={Mg_Arrow}
                          alt="Magento Migration"
                          className="img-adjust"
                      />
                    </span>
                          <p>
                            Magento 2’s ElasticSearch feature helps improve the
                            quality of your website by giving you a unique search
                            experience on your e-shop.
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div className="mg__time--right ">
                <span>
                  <img
                      src={Mg_Time}
                      alt="Magento 2 Migration Time Process"
                      className="img-adjust"
                  />
                </span>
                      <h2>
                        CueForGood has helped several M1 Community and M1 Enterprise
                        stores <b>migrate to M2</b>.
                      </h2>
                      <h3>
                        Get in touch with us to know more about our capabilities and
                        get an estimate for upgrading your Magento store to M2.
                      </h3>
                      <div className="mg__contact--button">
                        <Link to="/contact/" className="btn main_cta focus-visible">
                          contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Clutch/>
        </Layout>
    )
  }
}